import { API } from '@/api/API'
import { linkTypesBackToGroups } from '@/api/Helpers'
import type { LinkedTG } from '@/api/types/processedEntities'
import { groupBy } from '@/helpers/IndexHelpers'

export function getPricedTicketGroups(id: string, groups: Array<LinkedTG>): Promise<Array<LinkedTG>> {
  const ttIds = groups.map((group) => group.types.map((type) => ({ ticket_type_id: type.id }))).flat()

  const path = `events/${id}/pricecheck`
  const args = { body: { ticket_types_required: ttIds } }
  return API.post(path, args).then(({ ticket_type }) => {
    const types = ticket_type._data
    const typesByGroupId = groupBy('ticket_group_id', types)
    // Merge groups with returned types
    const mergedGroups: Array<LinkedTG> = groups.map((group) => {
      return {
        ...group,
        types: typesByGroupId[group.id],
      } as LinkedTG
    })
    linkTypesBackToGroups(mergedGroups)
    return mergedGroups
  })
}
