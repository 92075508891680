import type { LinkedTG, LinkedTT } from '@/api/types/processedEntities'
import { sum } from '@/helpers/MiscellaneousHelpers'
import { exactlyOneTicket } from '@/helpers/Reserve'
import { lowerTrim } from '@/helpers/StringHelpers'
import type { TTQuantities } from '@/seats/helpers'
import type { CartItem, CartItemType, TimedItem } from '@/store/CartItem'
import type { Session } from '@/types/Sessions'

export function quantitiesPayload(quantities: TicketTypeQuantities) {
  // Filter out ticket type quantities where the quantity is 0
  // Requesting sold out ticket types, even if the ticket quantity requested is 0, will cause the API to return 'sold_out' for sessions
  return {
    ticket_types_required: Object.entries(quantities)
      .filter(([typeId, value]) => value.quantity > 0)
      .map(([typeId, value]) => ({
        ticket_type_id: typeId,
        quantity: value.quantity,
      })),
  }
}

export function filterQuantitiesByGroups(quantities: TicketTypeQuantities, groups: LinkedTG[]): TTQuantities {
  const result = {}
  for (const group of groups) {
    for (const type of group.types) {
      result[type.id] = quantities[type.id]?.quantity
    }
  }
  return result
}

export function quantitiesAreEqual(aQuantities: TicketTypeQuantities, bQuantities: TicketTypeQuantities): boolean {
  const aEntries = Object.entries(aQuantities)
  const bEntries = Object.entries(bQuantities)
  return (
    aEntries.length === bEntries.length &&
    aEntries.every(([key, a]) => {
      const b = bQuantities[key]
      return a.quantity === b.quantity && a.price === b.price
    })
  )
}

export function sumQuantities(quantities: TicketTypeQuantities): number {
  const values = Object.values(quantities).map((value) => value.quantity)
  return sum(values)
}

export function createQuantities(
  groups: LinkedTG[],
  session: Session | null,
  anchor: TimedItem | null,
): TicketTypeQuantities {
  // Attempt to match quantities for upsells on the same day.
  if (session && anchor && session.startTime.isSame(anchor.startTime, 'day')) {
    const result = matchingQuantities(groups, session, anchor)
    if (result) {
      return result
    }
  }

  // Default quantity to 1 if quantity steppers are not rendered.
  return initializeTTQuantities(groups, exactlyOneTicket(groups) ? 1 : 0)
}

function matchingQuantities(groups: LinkedTG[], session: Session, anchor: TimedItem): TicketTypeQuantities | void {
  const result = quantitiesFromCartItem(anchor, groups)
  const totalMatching = sumQuantities(result)
  // Only match quantities if there is sufficient capacity.
  if (totalMatching <= session.availableCapacity) {
    return result
  }
}

export function quantitiesFromCartItem(cartItem: CartItem, groups: LinkedTG[]): TicketTypeQuantities {
  const displayedTTs = mapDisplayedTicketTypes(groups)

  const result = initializeTTQuantities(groups, 0)
  for (const reserved of cartItem.types) {
    const type = getDisplayedTicketType(reserved, displayedTTs)
    if (type) {
      result[type.id].quantity = reserved.ticketCount
    }
  }
  return result
}

function getDisplayedTicketType(inCartType: CartItemType, displayedTTs: Dict<Dict<Dict<LinkedTT>>>): null | LinkedTT {
  const name = lowerTrim(inCartType.name)
  const groupName = lowerTrim(inCartType.group.name)
  const types = displayedTTs[name][inCartType.group.hidden_type]
  const list = Object.values(types)
  if (list.length == 1) {
    return list[0]
  } else {
    // There might not be a match.
    return types[groupName] ?? null
  }
}

function mapDisplayedTicketTypes(groups: LinkedTG[]): Dict<Dict<Dict<LinkedTT>>> {
  const result: Dict<Dict<Dict<LinkedTT>>> = {}
  for (const group of groups) {
    for (const type of group.types) {
      const name = lowerTrim(type.name)
      result[name] ??= {}
      result[name][group.hidden_type] ??= {}

      const groupName = lowerTrim(group.name)
      // Ignore additional TTs that match by TT name, TG name _and_ TG hidden_type. That is a misconfiguration.
      if (!result[name][group.hidden_type][groupName]) {
        result[name][group.hidden_type][groupName] = type
      }
    }
  }
  return result
}

function initializeTTQuantities(groups: LinkedTG[], quantity: number): TicketTypeQuantities {
  const result: TicketTypeQuantities = {}
  for (const group of groups) {
    for (const type of group.types) {
      result[type.id] = { quantity }
    }
  }
  return result
}
